import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import Alert from 'reactstrap/lib/Alert';
import { Link } from 'react-router-dom';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';

import { receiveTickets } from '@ttstr/actions';
import { Container, LoadingSpinner, Masonry, useIntl, ContentrArea } from '@ttstr/components';
import { AppState } from '@ttstr/reducers';
import { useActions, useShallowEqualSelector } from '@ttstr/utils';

const TicketListing: React.FC = () => {
  const { t } = useTranslation();
  const { loading, tickets } = useShallowEqualSelector(mapStateToProps);
  const { receiveTickets } = useActions(mapDispatchToProps);
  const { language } = useIntl();

  React.useEffect(() => {
    receiveTickets();
  }, [language]);

  return (
    <article className="ticket-listing">
      <Helmet>
        <title>{t(`TICKETLISTING.TITLE`)}</title>
        <body className="ticket-listing-page inverse-navbar" />
      </Helmet>

      <div className="heading my-5">
        <Container>
          <h1 className="">{t(`TICKETLISTING.TITLE`)}</h1>
        </Container>
      </div>

      <ContentrArea id="before-ticket-listing" />

      <Container className="listing-filter-buttons-container">
        <h5>PROGRAMM FILTERN:</h5>
        <Row className="mb-2 d-flex justify-items-center justify-content-center listing-buttons-container">
          <Col className="mt-3">
            <Link to="/tickets?tagId=7919" className="btn btn-lg btn-block btn-outline-dark btn-tickets h-100">
              {'Was soll passieren?'}
            </Link>
          </Col>
          <Col className="mt-3">
            <Link to="/tickets?tagId=7918" className="btn btn-lg btn-block btn-outline-dark btn-tickets h-100">
              {'Schund und Asche'}
            </Link>
          </Col>
          <Col className="mt-3">
            <Link to="/tickets?tagId=7916" className="btn btn-lg btn-block btn-outline-dark btn-tickets h-100">
              {'improvisiert'}
            </Link>
          </Col>
        </Row>
      </Container>

      {/* <div className="paralax-header">
        <h1 className="mb-4">{t(`TICKETLISTING.TITLE`)}</h1>
      </div> */}
      <Container className="list-page py-4">
        {/* <ContentrArea id="ticket-listing_before_listing" /> */}

        {loading ? ( // if loading
          <LoadingSpinner label={t(`LOADING.TICKETS`)} />
        ) : tickets.length === 0 ? ( // if no tickets
          <Alert color="info">{t(`MASONRY.NO_ITEMS`)}</Alert>
        ) : (
          // else show tickets
          <Masonry
            id="ticket-listing"
            products={tickets}
            type="list"
            showLocation
            filters={['fulltext']}
            showCity
            showTitle
            showTags
          />
        )}

        {/* <ContentrArea id="ticket-listing_after_listing" /> */}
      </Container>
      <Container>
        <ContentrArea id="after-ticket-listing" />
      </Container>
    </article>
  );
};

const mapStateToProps = (state: AppState) => {
  const { tickets, loading } = state.Tickets;
  return {
    tickets,
    loading,
  };
};

const mapDispatchToProps = {
  receiveTickets,
};

export default React.memo(TicketListing);
